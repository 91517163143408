import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["blogItem", "expander"]
  declare blogItemTarget: HTMLElement
  declare expanderTarget: HTMLElement

  static values = {
    showLabel: String,
    hideLabel: String
  }
  declare readonly showLabelValue: string
  declare readonly hideLabelValue: string

  async toggle() {
    const isExpanded = this.blogItemTarget.dataset.blogsExpanded === 'true'
    if (isExpanded) {
      this.blogItemTarget.dataset.blogsExpanded = 'false'
      this.expanderTarget.innerHTML = this.showLabelValue
    } else {
      this.blogItemTarget.dataset.blogsExpanded = 'true'
      this.expanderTarget.innerHTML = this.hideLabelValue
    }
    window.scrollTo({ top: this.blogItemTarget.offsetTop, behavior: 'smooth' })
  }
}
