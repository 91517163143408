import { Controller } from '@hotwired/stimulus'
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { post } from '@rails/request.js'

export default class extends Controller<HTMLFormElement> {
  static values = {
    createPath: String
  }
  declare readonly createPathValue: String

  connect() {
    this._askGetNotificationPermission()
  }

  _askGetNotificationPermission() {
    const firebaseConfig = {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
      appId: FIREBASE_APP_ID
    };

    const app = initializeApp(firebaseConfig);

    const messaging = getMessaging(app);

    // NOTE: プッシュ通知をフォアグラウンドで受け取ったときのカスタムイベント発行登録もやっちゃう
    onMessage(messaging, () => {
      document.dispatchEvent(new CustomEvent("receive_push_in_foreground"))
    })

    getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY }).then((currentToken: string) => {
      post(this.createPathValue, {
        responseKind: 'json',
        body: { device_token: { token: currentToken } }
      })
    });
  }
}
