import ApplicationController from "@/controllers/application_controller"
import $ from 'jquery';
import { Chart, registerables } from 'chart.js'
import type { ChartTypeRegistry } from 'chart.js'
Chart.register(...registerables)


export default class extends ApplicationController {
  static targets = ["canvas"]
  static values = {
    data: String
  }
  declare readonly canvasTarget: HTMLElement
  declare readonly dataValue: string

  connect() {
    const data = JSON.parse(this.dataValue)

    const config = {
      type: 'radar' as keyof ChartTypeRegistry,
      data: {
        labels: data.labels,
        datasets: [{
          data: [50.0, 50.0, 50.0, 50.0, 50.0, 50.0]
        },{
          fill: true,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          pointBackgroundColor: "rgb(255, 99, 132)",
          pointBorderColor: "rgb(255, 99, 132)",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(255, 99, 132)",
          data: data.values
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        showTooltips: false,
        startAngle: 30,
        plugins: {
          legend: { display: false }
        }
      }
    };
    new Chart($(this.canvasTarget), config);
  }
}
