import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // SEE: https://www.betterstimulus.com/error_handling/global-error-handler.html
  handleError(error: Error) {
    const context = {
      controller: this.identifier,
    };
    this.application.handleError(
      error,
      `Error in controller: ${this.identifier}`,
      context
    );
  };
}
