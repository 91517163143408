import ApplicationController from "@/controllers/application_controller"
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false

  static values = {
    path: String
  }
  static targets = ["vote", "count"]
  declare readonly voteTarget: HTMLInputElement
  declare readonly countTarget: HTMLElement
  declare readonly pathValue: String

  async toggle(e: any) {
    if(this.processing) { return }

    this.processing = true
    const voted:boolean = this.voteTarget.checked

    const method = voted ? 'delete' : 'post'
    const difference = voted ? -1 : 1
    this.voteTarget.checked = !voted
    this.countTarget.innerHTML = String(parseInt(this.countTarget.innerHTML) + difference)
    const request = new FetchRequest(method, this.pathValue, { responseKind: "turbo-stream" })
    const response = await request.perform()

    if(!response.ok) {
      this.voteTarget.checked = voted
    }
    this.processing = false
  }
}
