import ApplicationController from "@/controllers/application_controller"
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    path: String
  }
  declare readonly pathValue: string

  replace_form(e: any) {
    const response = get(this.pathValue, {
      responseKind: 'turbo-stream'
    })
  }
}
