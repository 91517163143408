import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["reviewItem", "expander"]
  declare reviewItemTarget: HTMLElement
  declare expanderTarget: HTMLElement

  static values = {
    showLabel: String,
    hideLabel: String
  }
  declare readonly showLabelValue: string
  declare readonly hideLabelValue: string

  async toggle() {
    const isExpanded = this.reviewItemTarget.dataset.reviewsExpanded === 'true'
    if (isExpanded) {
      this.reviewItemTarget.dataset.reviewsExpanded = 'false'
      this.expanderTarget.innerHTML = this.showLabelValue
    } else {
      this.reviewItemTarget.dataset.reviewsExpanded = 'true'
      this.expanderTarget.innerHTML = this.hideLabelValue
    }
    window.scrollTo({ top: this.reviewItemTarget.offsetTop, behavior: 'smooth' })
  }
}
