import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = [ "file", "preview" ]

  declare readonly fileTarget: HTMLInputElement
  declare readonly previewTarget: HTMLImageElement

  preview() {
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          this.previewTarget.src = reader.result.toString()
        }
      }
      reader.readAsDataURL(this.fileTarget.files[0])
    }
  }
}
