import ApplicationController from "@/controllers/application_controller"
import { put } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    path: String,
  }
  declare readonly pathValue: String

  async toggle(e: any) {
    const target = e.currentTarget
    const isFollowing = target.dataset.userFollowing == 'on'
    const response = await put(this.pathValue, {
      body: {
        user_ignoring: { status: 'muting' }
      },
      responseKind: "turbo-stream",
    })
    if(response.ok) {
      this._toggle_condition(target)
    } else {
      response.renderTurboStream()
    }
  }

  _toggle_condition(target: HTMLElement) {
    const condition = target.dataset.userMuting
    if (condition == 'on') {
      target.dataset.userMuting = 'off'
    } else {
      target.dataset.userMuting = 'on'
    }
  }
}
