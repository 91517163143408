import ApplicationController from "@/controllers/application_controller"
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false
  isCreated: boolean = false

  static values = {
    path: String
  }
  declare readonly pathValue: string

  async create() {
    if (this.isCreated) return
    if (this.processing) return

    this.processing = true
    const response = await post(this.pathValue, { responseKind: "json" })
    if (response.ok) this.isCreated = true
    this.processing = false
  }
}
