import ApplicationController from "@/controllers/application_controller"
import { put } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    path: String
  }
  declare readonly pathValue: String

  async skip(e: any) {
    const response = await put(this.pathValue, {
      responseKind: 'json'
    })
    if (response.ok) {
      const json = await response.json
      history.pushState(
        { turbo_frame_history: true },
        "",
        json.path
      )
      window.location.href = json.path
    }
  }
}
