import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static values = {
    title: String,
    description: String
  }
  declare readonly titleValue: string
  declare readonly descriptionValue: string

  connect() {
    this._updateTitle()
    this._updateMeta('description', this.descriptionValue)
  }

  _updateMeta(name: string, content: string) {
    const meta = document.querySelector(`meta[name="${name}"]`)
    if (meta) {
      meta.setAttribute('content', content)
    }
    const ogMeta = document.querySelector(`meta[property="og:${name}"]`)
    if (ogMeta) {
      ogMeta.setAttribute('content', content)
    }
    const twitterMet = document.querySelector(`meta[name="twitter:${name}"]`)
    if (twitterMet) {
      twitterMet.setAttribute('content', content)
    }
  }
  
  _updateTitle() {
    document.title = this.titleValue
    this._updateMeta('title', this.titleValue)
  }
}
