import ApplicationController from "@/controllers/application_controller"
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false
  static targets = ["frame"]
  static values = {
    path: String
  }
  declare readonly pathValue: string
  declare readonly frameTarget: HTMLElement

  async import() {
    if(this.processing) { return }
    this.processing = true

    this.frameTarget.style.display = "block"
    await post(this.pathValue, { responseKind: 'turbo-stream' })
    this.processing = false
  }
}
