import ApplicationController from "@/controllers/application_controller"
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    path: String,
  }
  declare readonly pathValue: String

  async toggle(e: any) {
    const target = e.currentTarget
    const isFollowing = target.dataset.userFollowing == 'on'
    const method = isFollowing ? 'delete' : 'post'
    const request = new FetchRequest(method, this.pathValue, {
      responseKind: "turbo-stream",
    })
    const response = await request.perform()
    if(response.ok) {
      this._toggle_condition(target)
    } else {
      response.renderTurboStream()
    }
  }

  _toggle_condition(target: HTMLElement) {
    const condition = target.dataset.userFollowing
    if (condition == 'on') {
      target.dataset.userFollowing = 'off'
    } else {
      target.dataset.userFollowing = 'on'
    }
  }
}
