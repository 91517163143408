import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["add_item", "template"]

  declare readonly templateTarget: HTMLElement
  declare readonly add_itemTarget: HTMLElement

  add_association(event: any) {
    event.preventDefault()  
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf().toString())
    this.add_itemTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association(event: any) {
    event.preventDefault()
    event.target.closest(".nested-fields").remove()
  }
}