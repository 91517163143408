import ApplicationController from "@/controllers/application_controller"
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false

  static values = {
    path: String
  }
  declare readonly pathValue: String

  async toggle(event: any) {
    if(this.processing) return

    this.processing = true
    const currentTarget = event.currentTarget as HTMLElement
    const isLiked:boolean = currentTarget.dataset.commentLiked === "true"
    currentTarget.dataset.commentLiked = String(!isLiked)
    const diff = isLiked ? -1 : 1
    currentTarget.textContent = String(parseInt(currentTarget.textContent || '0') + diff)

    const method = isLiked ? 'delete' : 'post'
    const request = new FetchRequest(method, this.pathValue, { responseKind: "turbo-stream" })
    const response = await request.perform()

    if(!response.ok) {
      currentTarget.dataset.commentLiked = String(!isLiked)
      currentTarget.textContent = String(parseInt(currentTarget.textContent || '0') - diff)
      response.renderTurboStream()
    }
    this.processing = false
  }
}
