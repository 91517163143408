import { Application } from "@hotwired/stimulus"
import { initSentryInStimlus } from "@/utils/sentry";

declare global {
  interface Window {
    Stimulus: Application;
  }
}

const application = Application.start()
initSentryInStimlus(application)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
