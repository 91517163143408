import ApplicationController from "@/controllers/application_controller"
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  limitMillisecond: number = 1000

  static targets = [
    "input",
    "slider",
    "scoredBar"
  ]

  static values = {
    path: String,
    gameComparisonItemId: Number
  }

  declare readonly inputTarget: HTMLInputElement
  declare readonly sliderTarget: HTMLElement
  declare readonly scoredBarTarget: HTMLElement
  declare readonly pathValue: String
  declare readonly gameComparisonItemIdValue: Number

  connect() {
    $(this.sliderTarget).slider({
      range: false,
      min: 0,
      max: 100,
      step: 1,
      disabled: this.sliderTarget.dataset.disabled === 'true',
      animate: 'fast',
      value: this.inputTarget.value,
      // NOTE: connectの段階では別関数内でthis.inputTargetがundefinedになるので引数として渡す
      slide: this._onSlide(this.inputTarget)
    });
  }

  _onSlide(input: HTMLInputElement) {
    return (event: any, ui: any) => {
      const score: number = ui.value;
      input.value = JSON.stringify(score);
      this.scoredBarTarget.style.width = score + '%';

      // データを飛ばす部分
      if (this.sliderTarget.dataset.timeoutId !== null) {
        clearTimeout(Number(this.sliderTarget.dataset.timeoutId));
      }
      const timeoutId = setTimeout(
        this._updateScore(score),
        this.limitMillisecond
      );
      this.sliderTarget.dataset.timeoutId = JSON.stringify(timeoutId);
    }
  }

  _updateScore(score: Number) {
    return () => {
      post(this.pathValue, {
        responseKind: "turbo-stream",
        body: {
          user_game_comparison: {
            game_comparison_item_id: this.gameComparisonItemIdValue,
            score: score,
          }
        }
      })
    }
  }
}
