import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["overlay"]
  declare overlayTarget: HTMLElement

  reveal() {
    this.overlayTarget.dataset.blogMuted = 'false'
  }
}
