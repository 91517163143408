import ApplicationController from "@/controllers/application_controller";
import { post } from '@rails/request.js'

export default class extends ApplicationController {
  static values = {
    path: String
  }
  declare readonly pathValue: string


  confirm() {
    post(this.pathValue, {
      responseKind: 'json'
    })
  }
}
