import ApplicationController from "@/controllers/application_controller"
import { post, destroy } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false

  async on_click(e: any) {
    if(this.processing) { return }

    this.processing = true
    const { path, positioning } = e.params
    const voted = e.currentTarget.dataset.playstyleVoted === 'true'
    e.currentTarget.dataset.playstyleVoted = !voted

    // NOTE: クリックした瞬間の切り替えなどは面倒なのでTurboに任せる
    if(!voted) {
      await post(path, {
        responseKind: "turbo-stream",
        body: {
          user_game_playstyle: {
            positioning: positioning
          }
        }
      })
    } else {
      await destroy(path, {
        responseKind: "turbo-stream"
      })
    }

    this.processing = false
  }
}
