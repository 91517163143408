import ApplicationController from "@/controllers/application_controller"
import { put } from '@rails/request.js'
import { captureResponseToSentry } from '@/utils/sentry'

export default class extends ApplicationController {
  static values = {
    path: String
  }
  static targets = ['checkbox']

  declare readonly pathValue: String
  declare readonly checkboxTarget: HTMLInputElement

  async toggle() {
    const response = await put(this.pathValue, {
        responseKind: 'turbo-stream',
        body: { enabled: this.checkboxTarget.checked }
      }
    )
    if (!response.ok) {
      captureResponseToSentry("toggle_user_push_notification_setting", response, { path: this.pathValue })
      response.renderTurboStream()
      this.checkboxTarget.checked = !this.checkboxTarget.checked
    }
  }
}
