import ApplicationController from '@/controllers/application_controller'

export default class extends ApplicationController {
  static values = {
    message: String
  }
  static targets = ['form']

  declare readonly messageValue: string
  declare readonly formTarget: HTMLFormElement

  confirm() {
    if(!window.confirm(this.messageValue)) return

    this.formTarget.submit()
  }
}
