import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'froala',
  ]

  static values = {
    froalaLanguage: String,
    placeholder: String
  }

  declare readonly froalaTarget: HTMLInputElement
  declare readonly froalaLanguageValue: string
  declare readonly placeholderValue: string

  async froalaTargetConnected() {
    // NOTE: froalaEditorが重すぎるのでconnectedのときだけ読み込む
    const mountFroalaEditor = (await import('../common/froala_editor')).default;
    mountFroalaEditor(
      `#${this.froalaTarget.id}`,
      {
        language: this.froalaLanguageValue,
        placeholderText: this.placeholderValue,
      }
    )
  }
}
