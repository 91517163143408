import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['element']
  declare readonly elementTargets: HTMLElement[]

  toggle(params: { element: string, class: string }) {
    const element = this.elementTargets.find((e) => {
      return e.dataset.identifier === params.element
    })
    if(element) {
      element.classList.toggle(params.class)
    }
  }
}
