import ApplicationController from "@/controllers/application_controller"
import { FetchRequest } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false

  static values = {
    path: String
  }
  declare readonly pathValue: Number

  async toggle(event: any) {
    if(this.processing) { return }
    this.processing = true
    const currentTarget = event.currentTarget as HTMLElement
    const ignored = currentTarget.dataset.mygameIgnored === 'true'
    event.currentTarget.dataset.mygameIgnored = String(!ignored)

    const method = ignored ? 'delete' : 'post'
    const request = new FetchRequest(method, this.pathValue, {
      responseKind: "turbo-stream"
    })
    const response = await request.perform()

    if(!response.ok) {
      currentTarget.dataset.mygameIgnored = String(ignored)
    }
    this.processing = false
  }
}
