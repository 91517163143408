import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["subGenre"]
  declare readonly subGenreTargets: HTMLInputElement[]

  check_subs(e: any) {
    this.subGenreTargets.forEach((subGenre) => {
      subGenre.checked = e.currentTarget.checked
    })
  }
}
