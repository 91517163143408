import ApplicationController from "@/controllers/application_controller"
import { post, destroy } from '@rails/request.js'

export default class extends ApplicationController {
  processing: boolean = false

  static values = {
    path: String
  }
  static targets = ["stars"]
  declare readonly starsTarget: HTMLInputElement
  declare readonly starsTargets: HTMLInputElement[]
  declare readonly pathValue: String

  async click_star(event: any) {
    if(this.processing) { return }
    this.processing = true
    const originalValue = this.starsTarget.dataset.mygameStars ?? '0'
    const newValue = String(event.params.value)
    if (originalValue !== newValue) {
      await this._update_value(newValue, originalValue)
    } else {
      await this._delete_value(originalValue)
    }
    this.processing = false
  }

  async _update_value(newValue: string, originalValue: string) {
    this._set_stars(newValue)
    const response = await post(this.pathValue, {
      responseKind: "turbo-stream",
      body: {
        rating: {
          value: newValue
        }
      }
    })
    if(!response.ok) {
      this._set_stars(originalValue)
    }
  }

  async _delete_value(originalValue: string) {
    this._set_stars('0')
    const response = await destroy(this.pathValue, {
      responseKind: "turbo-stream"
    })
    if(!response.ok) {
      this._set_stars(originalValue)
    }
  }

  _set_stars(newValue: string) {
    this.starsTargets.forEach(e => {
      e.dataset.mygameStars = newValue
    })
  }
}
