import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static targets = ["tab"]
  static values = {
    defaultTab: String
  }
  declare readonly tabTargets: HTMLElement[]
  declare readonly defaultTabValue: string

  connect() {
    this.tabTargets.forEach(e => {
      if (e.dataset.tabType == this.defaultTabValue) {
        e.style.display = 'flex'
      }
    })
  }

  select(e: any) {
    this.tabTargets.forEach(tabTarget => {
      if (tabTarget.dataset.tabType == e.currentTarget.value) {
        tabTarget.style.display = 'flex'
      } else {
        tabTarget.style.display = 'none'
      }
    })
  }
}
