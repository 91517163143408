import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {

  static targets = ["item", "copy"]
  declare readonly itemTarget: HTMLElement
  declare readonly copyTarget: HTMLElement

  static values = {
    successMessage: String,
    text: String
  }
  declare readonly successMessageValue: string
  declare readonly textValue: string

  copy() {
    navigator.clipboard.writeText(this.textValue).then(() => {
      this.itemTarget.dataset.successMessageShowing = 'true';
      setTimeout(() => {
        this.itemTarget.dataset.successMessageShowing = 'false';
      }, 1500);
    });
  }
}
