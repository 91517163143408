import ApplicationController from "@/controllers/application_controller"
import { get } from '@rails/request.js'
import { captureResponseToSentry } from '@/utils/sentry'

export default class extends ApplicationController {
  static values = {
    path: String
  }
  declare readonly pathValue: string

  boundReload = () => {}

  connect() {
    this.boundReload = this.reload.bind(this)
    document.addEventListener("receive_push_in_foreground", this.boundReload)
  }

  disconnect() {
    document.removeEventListener("receive_push_in_foreground", this.boundReload)
  }

  async reload() {
    const response = await get(this.pathValue, { responseKind: "turbo-stream" })
    if (!response.ok) {
      captureResponseToSentry("receive_push_in_foreground", response, { path: this.pathValue })
    }
  }
}
